import React from 'react'
import LegalTemplate from '../templates/legal'
import RichText from '../components/rich-text'

const LegalPage = () => {
  return (
    <LegalTemplate title="Disclaimer">
      <RichText>
        <p>
          All images unless otherwise indicated are not taken or owned by Title
          Scream. They are used solely for the purpose of discussion, comment or
          visual aid. All images are not intended for any commercial use. If you
          would like an image removed from the site please e-mail us at{' '}
          <a href="mailto:info@titlescream.com">info@titlescream.com</a> and it
          will be removed as soon as possible.
        </p>
        <p>
          The images on this site may contain links to other websites and
          software. We are not responsible for the privacy practices or the
          content of these websites or software. Please visit the privacy
          policies of these third party sites in order to understand their
          privacy and information collection practices.
        </p>
      </RichText>
    </LegalTemplate>
  )
}

export default LegalPage
