import React from 'react'
import PropTypes from 'prop-types'
import styles from './legal.module.scss'

const LegalTemplate = ({ title, children }) => {
  return (
    <div className={styles.el}>
      <div className={styles.el__inner}>
        <h1 className={styles.el__title}>{title}</h1>
        <div className={styles.el__content}>{children}</div>
      </div>
    </div>
  )
}

LegalTemplate.defaultProps = {
  children: undefined,
  title: '',
}

LegalTemplate.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
}

export default LegalTemplate
